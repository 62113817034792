<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Redes Sociales - Configuración</h4>
            <div class="small text-muted">Configurar la conexión para conectarnos al servicio</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
              

            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.REDES_SOCIALES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: { 
          form: {
            id: 1,         
            name: '',   
            cod_empresa: 0,                       
            active: false,
            nroCUIT: '',
          },             
        },
        tabIndex: 0,          
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      // this.showBCRA()      
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      }, 
            
      showBCRA() {        
        var result = serviceAPI.mostrarBCRA(this.crud.form.id)

        result.then((response) => {
          var data = response.data
          
          this.crud.form.name = data.name
          this.crud.form.cod_empresa = data.cod_empresa 
          this.crud.form.active = data.active                        
        })
      },
      saveBCRA() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de BCRA?', {
          title: 'Guardar BCRA',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {            
            let loader = this.$loading.show();
            var result = serviceAPI.editarBCRA(this.crud.form);

            result.then((response) => {          
              loader.hide()          
              this.showBCRA()
              this.$awn.success("Datos del BCRA guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      testBCRA() {                        
        if (this.crud.form.nroCUIT.length != 11) {          
          this.$awn.alert("No se puede realizar el test sino se ingresa un cuit válido")          
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Probar configuración de BCRA?', {
          title: 'Configuración de email',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.testBCRA(this.crud.form)

            result.then((response) => {                        
              loader.hide()
              this.showBCRA()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },            
    } 
  }
</script>